.top_select[data-v-6aab24f7] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.iconStyle[data-v-6aab24f7] {
  margin: 0 5px;
  cursor: pointer;
  color: var(--themeColor, #17a2b8);
  font-size: 18px;
}
.icon-shanchu[data-v-6aab24f7] {
  color: #fe696a;
}
[data-v-6aab24f7] .el-dropdown-menu__item {
  display: block;
}